import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

const BlogThumbL = ({ post }) => (
  <div
    className="
      px-4
      pt-32
      pb-20
    "
  >
    <Link
      className="
        flex
        flex-col
        md:flex-row
      "
      to={post.frontmatter.slug}
    >
      <div
        className={`
          ${post && post.frontmatter && post.frontmatter.preview ? null : 'aspect-ratio-32/9'}
          bg-blue-200
          overflow-hidden
          rounded-lg
          md:w-1/2
        `}
      >
        { post && post.frontmatter && post.frontmatter.preview &&
          <img src={`/images/blog-thumbs/${post.frontmatter.preview}`} />
        }
      </div>
      <p
        className="
          flex
          flex-col
          justify-center
          py-4
          md:px-4
        "
      >
        <span
          className="
            font-black
            text-3xl
            text-blue-800
          "
        >{post.frontmatter.title}</span>
        <span
          className="
            font-mono
            text-gray-400
          "
        >{post.frontmatter.date}</span>
      </p>
    </Link>
  </div>
)

export default BlogThumbL

BlogThumbL.propTypes = {
  post: PropTypes.object,
}
