import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import BlogThumbM from '../components/blogThumbM'
import BlogThumbL from '../components/blogThumbL'
import BlogThumbS from '../components/blogThumbS'

const NUMBER_OF_LATEST_ARTICLES = 4

const Blog = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {

  const AllPosts = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => 
      <BlogThumbS
        key={edge.node.id}
        post={edge.node}
      />
    )

  const LatestPost = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .splice(0, 1)
    .map(edge =>
      <BlogThumbL
        key={edge.node.id}
        post={edge.node}
      />
    )

  const LatestPosts = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .splice(1, NUMBER_OF_LATEST_ARTICLES)
    .map(edge =>
      <BlogThumbM
        key={edge.node.id}
        post={edge.node}
      />
    )

  let Topics = []
  edges.forEach(element => {
    Topics.push(element.node.frontmatter.topic)
  })
  Topics = [...new Set(Topics)]
    .map(topic =>
      <p
        className="text-gray-700"
        key={topic}
      >{topic}</p>
    )

  return (
    <Layout>
      <Seo title="Blog" />
      <div
        className="
          flex
          flex-col
          justify-center
          ml-auto
          mr-auto
          max-w-6xl
          min-h-screen
          py-56
        "
      >
        <h1
          className="
            font-black
            leading-snug
            max-w-4xl
            mx-auto
            py-4
            text-4xl
            md:text-5xl
            text-center
            text-blue-800
          "
        >Latest Articles</h1>
        <h2
          className="
            max-w-4xl
            mx-auto
            text-lg
            md:text-xl
            text-center
            text-blue-800
          "
        >We write about the latest events and news surrounding our podcasting software and the industry.
        </h2>
        {LatestPost}
        <div
          className="
            flex
            flex-wrap
          "
        >{LatestPosts}</div>
        <h2
          className="
            font-black
            leading-snug
            max-w-4xl
            mx-auto
            py-4
            text-4xl
            md:text-5xl
            text-center
            text-blue-800
          "
        >All Articles
        </h2>
        {AllPosts}
      </div>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            preview
            slug
            thumb
            title
            topic
          }
        }
      }
    }
  }
`

Blog.propTypes = {
  data: PropTypes.object,
}
