import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

const BlogThumbS = ({ post }) => (
  <div
    className="
      px-4
      md:px-0
      py-2
    "
  >
    <Link
      className="
        flex
        flex-wrap
        items-center
      "
      to={post.frontmatter.slug}
    >
      <div
        className="
          hidden
          md:static
          md:w-16
          mr-4
        "
      >
        <div
          className={`
            ${post && post.frontmatter && post.frontmatter.thumb ? null : 'aspect-ratio-square'}
            bg-blue-200
            overflow-hidden
            rounded-md
            md:rounded-lg
          `}
        >
          { post && post.frontmatter && post.frontmatter.thumb &&
            <img src={`/images/blog-thumbs/${post.frontmatter.thumb}`} />
          }
        </div>
      </div>
      <div
        className="
          items-start
          justify-start
          flex
          flex-col
          md:flex-row
        "
      >
        <span
          className="
            font-black
            text-blue-800
            text-md
            md:text-lg
          "
        >
          {post.frontmatter.title}
        </span>
        <span
          className="
            font-mono
            md:pl-2
            text-gray-400
          "
        > {post.frontmatter.date}</span>
      </div>
    </Link>
  </div>
)

export default BlogThumbS

BlogThumbS.propTypes = {
  post: PropTypes.object,
}
