import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

const BlogThumbM = ({ post }) => (
  <div
    className="
      w-1/2
      md:w-1/4
    "
  >
    <Link
      className="
        flex
        flex-col
        p-4
      "
      to={post.frontmatter.slug}
    >
      <div
        className={`
          ${post && post.frontmatter && post.frontmatter.thumb ? null : 'aspect-ratio-square'}
          bg-blue-200
          overflow-hidden
          rounded-lg
        `}
      >
        { post && post.frontmatter && post.frontmatter.thumb &&
          <img src={`/images/blog-thumbs/${post.frontmatter.thumb}`} />
        }
      </div>
      <div
        className="
          flex
          flex-col
          justify-center
          px-2
          py-4
        "
      >
        <span
          className="
            font-black
            text-2xl
            text-blue-800
          "
        >{post.frontmatter.title}</span>
        <span
          className="
            font-mono
            text-gray-400
          "
        >{post.frontmatter.date}</span>
      </div>
    </Link>
  </div>
)

export default BlogThumbM

BlogThumbM.propTypes = {
  post: PropTypes.object,
}
